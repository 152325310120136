//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TuoShiLOGO from "../../components/TuoShiLOGO.vue";
export default {
  components: { TuoShiLOGO },
  data() {
    return {
      // 日期选择器
      date: "",
      show: false,
      minDate: new Date(2021, 1, 1),
      maxDate: new Date(2023, 1, 1),
      // 考勤选择
      value: "全部记录",
      radio: "1",
      showPicker: false,
      result: [],
      // 表格
      allAlign: null,
      tableData: [
        {
          time: "2011/01/12 12:35:23",
          site: "食堂",
          cen: "消费10元",
        },
        {
          time: "2011/01/12 12:35:23",
          site: "食堂",
          cen: "充值10元",
        },
        {
          time: "2011/01/12 12:35:23",
          site: "食堂",
          cen: "充值10元",
        },
      ],
    };
  },
  methods: {
    rowClassName({ row }) {
      if (row.cen == "消费10元") {
        console.log("ads");
        return "row-green";
      } else if (row.cen == "充值10元") {
        console.log("ads");
        return "row-red";
      }
    },

    // 轨迹选择
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    // 时间选择器
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      console.log("asdasd");
      const [start, end] = date;
      this.show = false;
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`;
    },
    // 考勤选择
    onConfirm1(e) {
      this.radio = e;
      if (e == "1") {
        this.value = "全部记录";
      }
      if (e == "2") {
        this.value = "充值记录";
      }
      if (e == "3") {
        this.value = "消费记录";
      }
      this.showPicker = false;
    },
    // 单元格点击跳转详情
    cellClickEvent() {
      this.$router.push("/Particulars");
    },
  },
};
